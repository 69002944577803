































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import {Permission} from '@/misc/enums/permission.enum';

const CustomerStore = namespace('customer');
const UserStore = namespace('user');

interface Filter {
  locations: 'all' | 'selected' | 'none';
  customers: 'all' | 'selected' | 'none';
  users: 'all' | 'selected' | 'none';
}

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
    FilterRadioComponent: () => import(
        '@/components/shared/FilterRadio.component.vue'),
    RJAutocomplete: () => import(
        '@/components/shared/custom-vuetify/RJAutocomplete.vue'),
  },
})
export default class MapFilterComponent extends Vue {

  @Prop({default: true})
  public autofocus!: boolean;

  public selectedCustomers: Customer[] = [];
  public selectedUsers: User[] = [];
  public selectedManager: User[] = [];
  public selectedLocations: Location[] = [];

  public markerObjects: any = {
    customer: [],
    location: [],
    user: [],
  };

  /**
   * Bool to filter locations according to the selected managers
   * @private
   */
  private filterLocations: 'all' | 'selected' | 'none' = 'all';
  /**
   * Bool to show the items on the map
   * @private
   */
  private show: Filter = {
    users: 'all',
    customers: 'all',
    locations: 'all',
  };

  /**
   * All loaded locations of the active customers of the active company
   * @private
   */
  private loadedLocations: Location[] = [];

  @CustomerStore.Action('loadCustomersAction')
  private loadCustomersAction!: (companyId: string) => Promise<Customer[]>;
  @UserStore.Action('loadUsersAction')
  private loadUsersAction!: (payload: { companyId: string, options?: any }) => Promise<User[]>;
  @CustomerStore.Action('loadCustomerAction')
  private loadCustomerAction!: (customerId: string) => Promise<Customer>;
  @CustomerStore.Action('loadLocationsAction')
  private loadLocationsAction!: (payload: { populate: string[] }) => Promise<Location[]>;

  @UserStore.Getter('users')
  private _users!: User[];
  @CustomerStore.Getter('customers')
  private _customers!: Customer[];

  /**
   * Get all active users with the role 'manager' or 'objectManager'
   */
  public get manager(): User[] {
    return this._users ? this._users
        .filter((user) => (user.role!.isTenantAdmin ||
            user.role?.canBeLocationManager)) : [];
  }

  /**
   * Get all active users with the role 'employee'. Filter optionally for having a geoPosition
   * @param filter
   */
  public users(filter?: boolean): User[] {
    return this._users ? this._users
        .filter((user) => user.role!.hasPermission(Permission.WORKSESSION_READ_OWN) && user.active
            && (filter ? user.address && user.address?.geoPosition : true)) : [];
  }

  /**
   * Get all customers. Optionally filter for having a geoPosition
   */
  public get customers(): Customer[] {
    return this._customers;
  }

  /**
   * Filters the location according to the selected managers. Optionally filter for having a geoPosition
   * @param filterForAddress
   */
  public filteredLocations(filterForAddress?: boolean): Location[] {
    if (this.selectedManager.length > 0 && this.filterLocations) {
      const managers: string[] = [];
      this.selectedManager.forEach((manager) => managers.push(manager.id!));
      return this.locations(filterForAddress).filter((location) => location.managers.filter((manager) =>
          managers.includes((manager as User).id!)).length > 0);
    }
    return this.locations(filterForAddress);
  }

  /**
   * Get all locations from the loaded customers. Can filter for having a geoPosition
   */
  public locations(filter?: boolean): Location[] {
    const locations: Location[] = [];
    for (const customer of (this.selectedCustomers.length > 0 ? this.selectedCustomers : this.customers)) {
      for (const location of this.loadedLocations.filter((l: Location) => l.customerId === customer.id)) {
        if (!location.address?.id) {
          location.address = customer.address;
        }
        // If the locations' customer does not match, or it has no address or geoPosition, don't push
        if (location.customerId === customer.id) {
          if (filter && !(location.address && location.address?.geoPosition)) {
            continue;
          }
          locations.push(location);
        }
      }
    }
    return locations;
  }

  public async mounted() {
    try {
      // Load the customers
      await this.loadCustomersAction(this.$route.params.companyId);
      // Load the users
      const params = {companyId: this.$route.params.companyId};
      await this.loadUsersAction(params);
      // Load the locations
      this.loadedLocations = await this.loadLocationsAction({populate: ['managers', 'address']});
      this.updateMarkerObjects();
      this.triggerCentralization();
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
    }
  }

  public getUserFullName(user: User) {
    return user.fullName;
  }

  public updateMarkerObjects() {
    // Make an array of the objects, that should be displayed on the map
    const markerObjects = [
      ...(this.show.customers !== 'none' ?
          (this.show.customers === 'selected' ? this.selectedCustomers : this.customers) :
          []),
      ...(this.show.locations !== 'none' ?
          (this.show.locations === 'selected' ? this.selectedLocations : this.filteredLocations()) :
          []),
      ...(this.show.users !== 'none' ?
          (this.show.users === 'selected' ? this.selectedUsers : this.users()) :
          [])];
    this.$emit('new-marker-objects', markerObjects);
  }

  /**
   * Method to delete item from filter
   */
  public onRemoveFromFilter(index: number, collection: any[]): void {
    collection.splice(index, 1);
    this.updateMarkerObjects();
  }

  private triggerCentralization() {
    this.$emit('centralize');
  }

  private onReloadClicked() {
    this.selectedManager = [];
    this.selectedUsers = [];
    this.selectedCustomers = [];
    this.selectedLocations = [];
    this.updateMarkerObjects();
  }
}
